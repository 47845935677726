import { useState } from 'react'
import './App.scss'

type IDoor = {
  name: string
  logo: string
  background: string
  desc: string
  link: string
}
const doors: IDoor[] = [
  {
    name: 'nemesia-production',
    logo: '/media/nemesia-logo-white.webp',
    background: '/media/nemesia-production.webp',
    desc: 'Votre vidéo sur mesure ',
    link: 'https://nemesia-production.com/',
  },
  {
    name: 'nemesia-ads',
    logo: '/media/nemesia-ads-logo.webp',
    background: '/media/nemesia-ads.webp',
    desc: 'Notre solution de diffusion ciblée',
    link: 'https://nemesia-ads.nemesia-production.com/',
  },
  {
    name: 'fasteo',
    logo: '/media/test-fasteo.webp',
    background: '/media/fasteo.webp',
    desc: 'Votre vidéo en moins de 48 heures',
    link: 'https://fasteo.nemesia-production.com/',
  },
]

function App() {
  const [selectedDoor, setSelectedDoor] = useState<string | null>(null)
  const handleHover = (door: string) => {
    console.log(door)
    setSelectedDoor(door)
  }
  return (
    <div className="App">
      {doors.map((door) => (
        <a
          href={door.link}
          target="_blank"
          rel="noreferrer"
          key={door.name}
          className={`door ${door.name} ${
            selectedDoor === door.name ? 'active' : null
          }`}
          style={{ backgroundImage: `url(${door.background})` }}
          onMouseEnter={() => handleHover(door.name)}
        >
          <div className="door-content">
            <div className="door-logo">
              <img src={door.logo} alt={`logo ${door.name}`} />
            </div>
          </div>
          <div
            className={`door-desc ${
              selectedDoor !== door.name ? 'hidden' : null
            }`}
          >
            {door.desc}
          </div>
          <div
            className={`door-filter ${door.name}-filter ${
              selectedDoor === door.name ? 'active' : null
            }`}
          />
        </a>
      ))}
    </div>
  )
}

export default App
